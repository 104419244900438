import React, { Component } from 'react'
import { Helm } from '../components/header'
import Container from 'react-bootstrap/Container'
import Header, { Item, Mempelai, Divider, Slider } from '../components/main'
import { Form, Row, Col, Alert } from 'react-bootstrap'
import AOS from 'aos';
import "aos/dist/aos.css";
import { cap } from '../params'
import 'bootstrap/dist/css/bootstrap.min.css';
import logo from '../assets/img/logo.ico'
import '../style/gold3.css'
import path from '../assets/img/gold3/path1.svg'
import wed2 from '../assets/img/gold3/wed2.jpg'
import manten from '../assets/img/gold3/manten.jpg'
import burung from '../assets/img/gold3/burung.svg'
import logoig from '../assets/img/donda/logoig.svg'
import logoig1 from '../assets/img/gold3/logoig1.svg'
import { gambar } from '../params'
import post from '../params/post'
import music from '../assets/music/donda.aac'
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css';

export default class Halo extends Component {
    constructor(props) {
        super(props);
        this.myRef = React.createRef()
        this.nama = React.createRef()
        this.alamat = React.createRef()
        this.pesan = React.createRef()


        this.state = {
            days: '00',
            minutes: '00',
            hours: '00',
            seconds: '00',
            hide: true,
            hadir: true,
            err: [],
            submitted: ''
        }
    }

    componentDidMount() {
        AOS.init({
            // initialise with other settings
            duration: 1000
        });
        let acara = [[
            'Akad Nikah',
            '10 oktober 2020',
            'Masjid Roudotul Muchlisin',
            '08:00 - 10:00 WIB'
        ],
        [
            'Resepsi',
            '10 oktober 2020',
            'Ijen View Hotel and Lounge',
            '17:00 - 21:00 WIB'
        ]
        ]
        this.setState({ acara: acara })
        var countDownDate = new Date("11/07/2020").getTime();

        // Update the count down every 1 second
        var x = setInterval(() => {

            // Get today's date and time
            var now = new Date().getTime();

            // Find the distance between now and the count down date
            var distance = countDownDate - now;

            // Time calculations for days, hours, minutes and seconds
            var days = Math.floor(distance / (1000 * 60 * 60 * 24));
            var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            var seconds = Math.floor((distance % (1000 * 60)) / 1000);
            this.setState({
                days: days, hours: hours, minutes: minutes, seconds: seconds
            })
        }, 1000);

    }   
    play = () => {
        AOS.refresh()
        var snd = new Audio(music);
        snd.type = 'audio/aac';
        snd.play();

        this.setState({ hide: false })
        setTimeout(() => {
            var elmnt = document.getElementById('top');
            elmnt.scrollIntoView();
        }, 1000)
    }

    handleSubmit = async () => {
        let err = []
        let local = localStorage.getItem('pesan')
        if (this.nama.current.value == "") {
            err.push('Nama tidak Boleh Kosong')
        }
        
        if (this.pesan.current.value == "") {
            err.push('Pesan tidak Boleh Kosong')
        }
        if (err.length == 0) {
            confirmAlert({
                message: local ? `Kami mendeteksi bahwa anda telah mengirimkan pesan \" ${local} \", apakah anda ingin mengirim pesan lagi?` : 'Yakin untuk Mengirim Pesan?',
                buttons: [
                    {
                        label: 'Yes',
                        onClick: async () => {
                            let send = await post(
                                ` dari: "${this.nama.current.value}", hadir: "", jumlahhadir: "", kepada: "donda-beauty", pesan:"${this.pesan.current.value}",alamat: ""`
                              )
                            if (send.status == 200) {
                                this.setState({ submitted: true })
                                localStorage.setItem('pesan', this.pesan.current.value)
                                this.nama.current.value = ''
                                this.pesan.current.value = ''
                            }else{
                                err.push('Koneksi Gagal')
                            }
                        }
                    },
                    {
                        label: 'No',
                        onClick: () => { }
                    }
                ]
            });
        } else {
            this.setState({ err: err, submitted: false })
        }


    }

    useQuery = () => {
        return new URLSearchParams(this.props.location.search);
    }
    render() {
        let { acara, days, hours, minutes, seconds, hide, hadir, err, submitted } = this.state
        let slide = ["	https://i.ibb.co/hD9rL5q/PHOTO-2020-10-30-14-29-41-1.jpg	",
            "	https://i.ibb.co/t48ChGG/PHOTO-2020-10-30-14-29-41-2.jpg	",
            "	https://i.ibb.co/PtZytFB/PHOTO-2020-10-30-14-29-41-3.jpg	",
            "	https://i.ibb.co/NFZ5cW0/PHOTO-2020-10-30-14-29-41-4.jpg	",
            "	https://i.ibb.co/8dLH68s/PHOTO-2020-10-30-14-29-41-5.jpg	",
            "	https://i.ibb.co/hBqPKpV/PHOTO-2020-10-30-14-29-41-6.jpg	",
            "	https://i.ibb.co/wcwTns2/PHOTO-2020-10-30-14-29-41-7.jpg	",
            "	https://i.ibb.co/kSVbSCM/PHOTO-2020-10-30-14-29-41-8.jpg	",
            "	https://i.ibb.co/zmYdbm7/PHOTO-2020-10-30-14-29-41-9.jpg	",
            "	https://i.ibb.co/wc7LP0Q/PHOTO-2020-10-30-14-29-41.jpg	",
        ]
        let slider = []
        slide.map(v => {
            slider.push(gambar(v, 95,
                'auto&func=fit&bg_img_fit=1&bg_opacity=0.75&w=1000&h=800'))
        })
        let query = this.useQuery().get('name');
        query = query ? cap(query) : ''
        return (
            <>
                <Helm
                    title='Undanganku - Donda & Beauty'
                    desc="undangan digital berbasis website untuk berbagai kebutuhan acara"
                    logo={logo}
                />
                <div id='gold3' >
                    <Container fluid id='g3-header' className='position-relative' style={{
                        backgroundImage: `url(${gambar('https://i.ibb.co/fFNrVjD/Modal-Depan-dan-Dalam.jpg', 95,
                            'auto&func=fit&bg_img_fit=1&bg_opacity=0.75&w=1800&h=900')})`,maxWidth:'100vw',overflowX:'hidden'
                    }}>
                        <div className="position-absolute w-100" style={{bottom:'10%',left:0}}>
                        <Item>
                            <Col xs={12} md={4} className='m-2 m-md-0 '>
                                <img className='img-fluid w-100 p-2'
                                    src={gambar('https://i.ibb.co/RC69S0c/Logo.png ')} data-aos="fade-left" />
                            </Col>
                        </Item>
                        <Item>
                            {
                                <h2 className={`col-md-4 roboto-slab text-center pt-5 pt-sm-3`}> 
                                Kepada Yth :<br/> {query?query:''} <br/></h2>

                            }</Item>
                        <Row className='justify-content-center'>
                            <div onClick={() => { this.play() }}
                            style={{color:'white'}}
                                 className={`col-md-4 button roboto-slab btn ${hide ? 'show' : 'hide'}`}>
                                Open Invitation
                            </div>
                        </Row>
                        
                        </div>
                        <Col className={`position-absolute p-0 ${hide ? 'hide' : 'show'}`} xs={12} style={{left:0,bottom:0,margin:"-5px"}}>
                            <img className='img-fluid w-100' src={path} />
                        </Col>
                    </Container>
                    {!hide ? (
                        <>
                            <Container fluid className='bg-white pt-3 pb-3' id='top'>
                                <Row className='justify-content-center'>
                                    <Col xs={10} md={6} className='p-3 rounded' style={{ backgroundColor: '#9072e0' }} >
                                        <Item>
                                            <div className='item'>
                                                <Item>
                                                    <div>
                                                        {days}
                                                    </div>
                                                </Item>
                                                <Item>
                                                    <span>
                                                        Days
                                        </span>
                                                </Item>
                                            </div>
                                            <div className='dot'>:</div>
                                            <div className='item'>
                                                <Item>
                                                    <div>
                                                        {hours}
                                                    </div>
                                                </Item>
                                                <Item>
                                                    <span>
                                                        Hours
                                            </span>
                                                </Item>
                                            </div>
                                            <div className='dot'>:</div>
                                            <div className='item'>
                                                <Item>
                                                    <div >
                                                        {minutes}
                                                    </div>
                                                </Item>
                                                <Item>
                                                    <span>
                                                        Mins
                      </span>
                                                </Item>
                                            </div>
                                            <div className='dot' >:</div>
                                            <div className='item'>
                                                <Item>
                                                    <div>
                                                        {seconds}
                                                    </div>
                                                </Item>
                                                <Item>
                                                    <span>
                                                        Secs
                      </span>
                                                </Item>
                                            </div>
                                        </Item>
                                    </Col>
                                </Row>
                                <Item>
                                    <Col xs={4} md={2} className='p-3'>
                                        <img src={burung} className='img-fluid w-100' />
                                    </Col>
                                </Item>
                                <Item>
                                    <Col xs={12} md={8}>
                                        <p className='italic c-main' style={{ color: '#676178' }}>
                                            Demikianlah mereka bukan lagi dua, melainkan satu. Karena itu, apa yang telah dipersatukan Allah, tidak boleh diceraikan manusia
                                    <br /><br />(Matius 19:6)
                            </p>
                                    </Col>
                                </Item>
                                <Item>
                                    <Col xs={12} md={8} className='p-3' style={{ color: '#676178' }}>
                                        <img src={gambar('https://i.ibb.co/hD9rL5q/PHOTO-2020-10-30-14-29-41-1.jpg', 95,
                                            'auto&func=fit&bg_img_fit=1&bg_opacity=0.75&w=1200&h=900')} className='img-fluid w-100 rounded' />
                                    </Col>
                                    <div className='p-3'>
                                        <Item>
                                            
                                                
                                            
                                            <Col xs={12} md={10} className='p-3 rounded' 
                                            style={{ background:'linear-gradient( rgba(255, 255, 255, 0.5), rgba(255,255,255, 0.6) ), url(https://i.ibb.co/sRvfM4w/Background-1.jpg)', 
                                             backgroundSize:'cover',backgroundPosition:'center'}}>
                                                <div>
                                                <p >
                                                Tuhan membuat segala sesuatu indah pada waktu-Nya, Indah saat mempertemukan, Indah saat Dia menumbuhkan kasih, dan Indah saat Dia mempersatukan Putra-Putri kami dalam suatu ikatan perkawinan kudus
                                                    </p>
                                                <Item>
                                                    <Col xs={6} md={3}>
                                                        <img src={gambar('https://i.ibb.co/b3xjSnS/image843.png', 95)} className='rounded img-fluid w-100' />
                                                    </Col>
                                                </Item>
                                                <Item>
                                                    <h1 className='sacramento p-3'>
                                                        Donald Jerry Salakory
                                        </h1>
                                                </Item>
                                                <Item>
                                                    <p className='s-bold poppins'>Putra Kedua Dari :</p>
                                                </Item>
                                                <Item>
                                                    <p className='poppins'>
                                                        Bpk. Marthin Salakory <br />
                                            dan<br />
                                            (✝️) Ibu. Mala Salakory
                                        </p>
                                                </Item>
                                                <Item>
                                                    <Col xs={3} md={1}>
                                                        <img src={logoig} className='img-fluid w-100 p-1 btn' onClick={() => {
                                                            window.open('http://instagram.com/Dondasalakory')
                                                        }} />
                                                    </Col>
                                                </Item>
                                                <Item>
                                                    <h1 className='sacramento p-3 larger' style={{ color: '#676178' }}>
                                                        &
                                    </h1>
                                                </Item>
                                                <Item>
                                                    <Col xs={6} md={3}>
                                                        <img src={gambar('https://i.ibb.co/RYKGj0V/image843.png', 95)} className='rounded img-fluid w-100' />
                                                    </Col>
                                                </Item>
                                                <Item>
                                                    <h1 className='sacramento p-3'>
                                                        Nathania Beauty Aurellia Siwalette
                                        </h1>
                                                </Item>
                                                <Item>
                                                    <p className='s-bold poppins'>Putri Kedua Dari :</p>
                                                </Item>
                                                <Item>
                                                    <p className='poppins'>
                                                        Bpk. Esau Siwalette <br />
                                            dan<br />
                                            Ibu. Bernanda I Siwalette-Leiwakabessy
                                        </p>
                                                </Item>
                                                <Item>
                                                    <Col xs={3} md={1}>
                                                        <img src={logoig} className='img-fluid w-100 p-1 btn' onClick={() => {
                                                            window.open('http://instagram.com/Beautynathania')
                                                        }} />
                                                    </Col>
                                                </Item>
                                                </div>
                                            </Col>
                                            
                                        </Item>
                                    </div>
                                </Item>
                            </Container>
                            <Container fluid className='bg-white pt-3 pb-3' id='save'>
                                <h1 className='poppins s-bold c-grey'>
                                    Save The Date
                        </h1>
                                <h1 className='sacramento s-bold mt-3 f-mid' style={{ color: '#676178' }}>
                                    Pemberkatan Perkawinan
                        </h1>
                                <p className='f-small poppins mt-4'>
                                    <div className='s-bold '>
                                        Sabtu, 7 November 2020
                                </div>
                            10.00 WIB<br />
                            GPIB Jemaat Bukit Moria Jakarta<br />
                                </p>
                                <Item>
                                    <Col xs={8} md={4}
                                        className='button poppins c-white f-small rounded p-1 btn'
                                        onClick={() => {
                                            window.open('https://youtu.be/vJxgbejQzIY')
                                        }}
                                        style={{ backgroundColor: '#D12322' }}
                                    >
                                        Watch on Youtube
                            </Col>
                                </Item>
                                <Item>
                                    <Col xs={8} md={4} className='button btn poppins c-white f-small rounded p-1 mt-3'
                                        style={{ backgroundColor: '#676178' }}
                                        onClick={() => {
                                            window.open('https://calendar.google.com/event?action=TEMPLATE&tmeid=NnQ3c2c5ZGk5NzBkOXEwczU1Z2RzMGZnN2YgYXJpZWZjNzJAbQ&tmsrc=ariefc72%40gmail.com')
                                        }}>
                                        Add to Google Calendar
                            </Col>

                                </Item>

                                <Item>
                                    <Col xs={12} md={8} className='border-pink poppins c-grey rounded p-2 m-4' style={{ fontSize: '16px', borderColor: '#676178' }}>
                                    Dikarenakan kondisi pandemi sekarang ini, sesuai dengan protokoler maka Ibadah Pemberkatan akan dihadiri oleh keluarga saja. Oleh karena itu, akan disiarkan secara langsung di kanal Youtube : GPIB BUKIT MORIA OFFICIAL
                            <br />
                            Terima Kasih
                            </Col>
                                </Item>
                                

                            </Container >
                            <div style={{ background:'linear-gradient( rgba(255, 255, 255, 0.5), rgba(255,255,255, 0.6) ), url(https://i.ibb.co/k0zZSz4/Backgorund-3.jpg)', 
                                             backgroundSize:'cover',backgroundPosition:'center'}}>
                            <Container fluid id='slider' className='p-3' style={{ backgroundColor: 'transparant' }} >
                                <Item><Col xs={12} md={8}>
                                    <Slider slide={slider} />
                                </Col>
                                </Item>

                            </Container>
                            <Container className=' pt-3' fluid id='form' style={{ backgroundColor: 'transparant' }}>
                                <Item>

                                    <Col xs={10} md={6} className='bg-white rounded poppins mt-3 pt-4 pb-4'>
                                        <h1 className='sacramento f-small send' style={{ color: '#676178' }}>
                                            Send Your Whises
                                        </h1>
                                        <form className="col-12 w-100 adnan">
                                            <input ref={this.nama} type='text' className="col-12 w-100 text-center" placeholder="Nama" name='nama' defaultValue={query ? query : ''} />
                                            <input ref={this.alamat} type='text' className="col-12 w-100 text-center" placeholder="Alamat" name='alamat' />
                                            <input ref={this.pesan} type='text-area' className="col-12 w-100 text-center bigger" placeholder="Pesan" name='pesan' />
                                            
                                            <Item>
                                                <Col xs={12} className=''>
                                                    {
                                                        submitted == true ? (
                                                            <Alert variant='success'>
                                                                Pesan anda sudah disampaikan
                                                            </Alert>) : (submitted === false ? (
                                                                <Alert variant='danger'>
                                                                    {
                                                                        err.map(val => {
                                                                            return (
                                                                                <li>{val}</li>
                                                                            )
                                                                        })
                                                                    }

                                                                </Alert>
                                                            ) : false)
                                                    }

                                                </Col>
                                            </Item>
                                            <Item>
                                                <div  className='col-6 button rounded btn'
                                                    onClick={() => this.handleSubmit()} style={{ backgroundColor: '#676178' }} no> Kirim </div>
                                            </Item>
                                        </form>

                                    </Col>
                                </Item>
                                <div className='pt-3'>
                                <Item>
                                        <p className='text-center p-3' style={{ fontSize: '20px' }}>
                                        For healthy issue, we suggest to give wedding gift by cashless system (QR code DANA or BCA)
                                        </p>
                                    </Item>
                                    <Item>
                                        <Col xs={6} sm={3} className='p-2'>
                                            
                                            <img className='img-fluid w-100' src={gambar('https://i.ibb.co/HxPtSHN/Whats-App-Image-2020-10-31-at-21-03-01-1.jpg', 90, 'auto&w=600&h=600')} />
                                            <p className='text-center' style={{
                                                fontSize: '24px',fontWeight:700
                                            }}>
                                                BCA
                                            </p>
                                        </Col>
                                        <Col xs={6} sm={3} className='p-2'>
                                            
                                            <img className='img-fluid w-100' src={gambar('https://i.ibb.co/SQQnC2M/Whats-App-Image-2020-10-31-at-21-03-01.jpg', 90, 'auto&w=600&h=600')} />
                                            <p className='text-center' style={{
                                                fontSize: '24px',
                                                fontWeight:700
                                            }}>
                                                DANA
                                                        
                                            </p>
                                        </Col>
                                    </Item>
                                    <Item>
                                        <p className='text-center p-3' style={{ fontSize: '20px' }}>
                                        Scan QR Code diatas pada aplikasi DANA atau BCA mobile banking
                                        </p>
                                    </Item>
                                    
                                </div>
                                <Item>
                                    <h1 className='sacramento f-small send pt-3 pb-3' 
                                    style={{ color: 'black', fontWeight: 800 ,fontSize:'48px'}}>
                                        Donda & Beauty
                                    </h1>
                                </Item>
                            </Container>
                            <Container fluid className='relative p-0' id='path' style={{ backgroundColor: 'transparant' }}>
                                <img className='absolute img-fluid w-100 mx' src={path} />
                            </Container>
                          

                            </div>
                            <Container fluid className='bg-thite poppins pb-5' id='footer'>
                                <Item>
                                    <h1 data-aos="zoom-in" data-aos-duration="1000">
                                        Possible Wedding
              </h1>
                                </Item>
                                <Item>
                                    <h2 data-aos="zoom-in" data-aos-duration="1000">
                                        Digital Invitation
              </h2>
                                </Item>
                                <Item>
                                    <div className='col-3 col-lg-1 p-md-4'>
                                        <img src={logoig1} className='img-fluid w-100'
                                        />
                                    </div>
                                </Item>
                            </Container>
                        </>
                    ) : false}
                </div>
            </>
        )
    }
}